import TagManager from 'react-gtm-module'
import constants from '../common/constants'
import { getRawItem, setRawItem } from './localStorageHelper'
const qs = require('qs')

export const createURL = (state) => `?${qs.stringify(state)}`

export const textTruncate = (str, length, ending) => {
  const newLength = length || 100
  const newEnding = ending || '...'
  if (str.length > newLength) {
    return str.substring(0, newLength - newEnding.length) + newEnding
  }
  return str
}

export const getSearchStateFilter = (searchState) => {
  const refinementList = { ...searchState.refinementList }
  if (searchState.refinementList) {
    Object.keys(refinementList).forEach((filter) => {
      if (Array.isArray(refinementList[filter])) {
        refinementList[filter] = refinementList[filter].map((value) => encodeURIComponent(value))
      }
    })
  }
  const routeState = {
    query: searchState.query ? encodeURIComponent(searchState.query) : searchState.query,
    page: searchState.page ? String(searchState.page) : '1',
    refinementList: Object.assign({}, refinementList, searchState.menu),
    sortBy: searchState.sortBy,
    hitsPerPage: searchState.hitsPerPage,
    minPrice: searchState.minPrice,
    maxPrice: searchState.maxPrice,
  }
  return routeState
}

export const validateInput = (validationRegex, inputText) => !!inputText.match(validationRegex)

export const getHitsPerPage = (hitsPerPage) => {
  if (!hitsPerPage || hitsPerPage < constants.HITS_PER_PAGE.MIN) return constants.HITS_PER_PAGE.MIN
  if (hitsPerPage > constants.HITS_PER_PAGE.MAX) return constants.HITS_PER_PAGE.MAX
  return hitsPerPage
}

export const urlToSearchState = (location) => {
  const params = qs.parse(location.search.slice(1))
  params.page = params.page ? Number.parseInt(params.page, 10) : 1
  params.receivedHitsPerPage = Number.parseInt(params.hitsPerPage, 10)
  params.hitsPerPage = getHitsPerPage(params.receivedHitsPerPage)
  return params
}

export const urlToBannerSlotState = (location) => {
  const params = qs.parse(location.search.slice(1))
  params.slot_id = params.slot_id || ''
  params.slot_name = params.slot_name || ''
  return { slotId: params.slot_id, slotName: params.slot_name }
}

export const searchStateToUrl = (location, searchState = {}) => {
  const routeState = {
    ...qs.parse(location.search.slice(1)),
    query: searchState.query,
    page: searchState && searchState.page ? searchState.page : 1,
    refinementList: searchState.refinementList,
    sortBy: searchState.sortBy,
    hitsPerPage: searchState.hitsPerPage || 16,
    minPrice: searchState.minPrice,
    maxPrice: searchState.maxPrice,
  }
  const queryString = qs.stringify(routeState, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
  })

  return `${location.pathname}${queryString}`
}

export const getSearchQuery = (location) => qs.parse(location.search.slice(1)).query || ''

const getSalesUnits = (listProduct, product) => listProduct.find((unit) => unit.sku === product.sku)

export const getMaxSalesUnitsForProduct = (listProduct, product) => {
  const salesUnits = getSalesUnits(listProduct, product)
  return salesUnits ? salesUnits.maxCant : constants.maxQuantity.UNLIMITED
}

export const isProductQuantityLessThanLimit = (listProduct, product) => {
  const salesUnits = getSalesUnits(listProduct, product)
  return salesUnits === undefined || product.quantity < salesUnits.maxCant
}

export const categoryNameFormatter = (category) => {
  const categoryNameArray = category.split('_')
  const newName = categoryNameArray.map((name) => {
    if (name !== 'y' && name !== 'Y' && name !== 'o' && name !== 'O') {
      return name.charAt(0).toUpperCase() + name.slice(1)
    }
    return name
  })
  return newName.join(' ')
}

export const getCustomSort = (arrayObject, keySort, order) => {
  if (order === 'DESC') {
    return arrayObject.sort((a, b) => {
      if (a[keySort] > b[keySort]) {
        return 1
      }
      if (a[keySort] < b[keySort]) {
        return -1
      }
      return 0
    })
  }
  return arrayObject.sort((a, b) => {
    if (a[keySort] < b[keySort]) {
      return 1
    }
    if (a[keySort] > b[keySort]) {
      return -1
    }
    return 0
  })
}

export const priceEnhancer = (price) => `$${parseInt(price, 0).toLocaleString('es-CL').replace(/,/g, '.')}`

export const getItemSavingAmount = (price) => {
  if (typeof price !== 'object' || !('BasePriceReference' in price) || !('BasePriceSales' in price)) {
    return
  }
  const normalPrice = Number(price.BasePriceReference)
  const actualPrice = Number(price.BasePriceSales)
  const saving = normalPrice - actualPrice
  if (saving < 0) {
    return
  }
  return saving
}

export const scrollToElement = (id, offset = 0) => {
  const element = document.getElementById(id)
  if (element) {
    const elementPosition = element.getBoundingClientRect().top
    const pageOffset = window.pageYOffset
    const offsetPosition = elementPosition + pageOffset - offset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}

export const chunk = (arr, len) => {
  const chunks = []
  const n = arr.length
  let i = 0

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

export const saveToLocalStorage = (key, data, ttl) => {
  const now = new Date()

  const item = {
    data,
    expiry: now.getTime() + ttl * 60000,
  }

  localStorage.setItem(key, JSON.stringify(item))
}

export const checkExternalContent = (brand) => {
  const externalContentBrands = [
    'LG',
    'SAMSUNG',
    'LOGITECH',
    'CIC',
    'LENOVO',
    'PHILIPS',
    'HISENSE',
    'AOC',
    'BELSOGNO',
    'ROSEN',
  ]
  return externalContentBrands.includes(brand.toUpperCase().trim())
}

export const getSpecialEventData = (categoryCarousel, location) => {
  let categoriesSpecialEvent = {}
  let showCarouselSpecialEvents = false
  const locationWithoutParameters = location.replace(/\?.*/, '')
  const categoryPath = locationWithoutParameters.split('category/')[1]
  const locationFixed = locationWithoutParameters.split('/')
  if (Array.isArray(categoryCarousel)) {
    categoryCarousel.forEach((element) => {
      if (
        locationFixed.some((category) => category === element.categoryName) ||
        categoryPath.startsWith(element.categoryName)
      ) {
        categoriesSpecialEvent = element
      }
    })
  }

  if (Object.keys(categoriesSpecialEvent).length !== 0 && categoriesSpecialEvent.enable) {
    showCarouselSpecialEvents = true
  }

  return { categoriesSpecialEvent, showCarouselSpecialEvents }
}

export const getTenant = (url) => {
  const splitter = (txt) => txt.split('/')[1]
  return url ? splitter(url) : splitter(window.location.pathname)
}

export const getNumberLength = (number, limit) => number >= limit

export const isAbsolutePath = (path) => {
  return path && (path.startsWith('http') || path.startsWith('www'))
}

export const redirectOpen = (redirect, target, navigatorPath) => {
  if (redirect && redirect !== '') {
    if (isAbsolutePath(redirect)) {
      window.open(redirect, target || '_blank')
      return
    }
    const redirectClean = redirect.startsWith('/') ? redirect : `/${redirect}`
    if (navigatorPath) {
      navigatorPath(redirectClean)
      return
    }
    const url = new URL(`${window.location.origin}/${getTenant()}${redirectClean}`)
    window.open(url, target || '_self')
  }
}

export const isCatex = () => getTenant() === constants.tenant.CATEX

export const isSod = () => getTenant() === constants.tenant.SOD

export const priceFormatter = (price) => `$${numberFormatter(price)}`

export const numberFormatter = (number) => `${number.toLocaleString('es-CL').replace(/,/g, '.')}`

export const getConfigData = () => window.__ENV__

export const priceParser = (price) => parseInt(price, 0)

export const normalizeText = (text) =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

const emailRegexValidator =
  /^s*(([^<>()[\]\\/.,;:\s@\\"]+(\.[^<>()[\]\\/.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/

export const emailValidationFunction = (givenEmail) => emailRegexValidator.test(givenEmail.trim())

export const passwordValidationFunction = (givenPassword) => {
  const regex = /^(?=.*[A-Za-z])^(?=.*[0-9])^(\S)+$/

  if (!regex.test(givenPassword)) {
    return false
  }

  return true
}

export const checkStringEmpty = (givenString) => givenString.trim().length === 0

export const checkStringMinLength = (givenString, length) => givenString.trim().length <= length

export const checkStringMaxLength = (givenString, length) => givenString.trim().length > length

export const productUrl = (tenant, { sku, slug }) =>
  `${encodeURI(window.location.origin)}/${tenant}/product/sku/${sku}/${slug}`

export const isProductPack = (price) => {
  if (price && price.packPrice && price.packSize) {
    const packPrice = Number(price.packPrice)
    const packSize = Number(price.packSize)
    return packPrice > 0 && packSize > 0
  }
  return false
}

export const getCssClasses = (defaultCss, flagToShowNextCss, nextCss) =>
  defaultCss.concat(flagToShowNextCss ? ' ' : '').concat(flagToShowNextCss ? nextCss : '')

export const tagManagerInitilize = () => {
  const {
    tenant: { CATEX, SOD },
  } = constants

  let gtmId = ''

  switch (getTenant()) {
    case CATEX:
      gtmId = window.__ENV__.tagManagerCatex
      break

    case SOD:
      gtmId = window.__ENV__.tagManagerSod
      break

    default:
      gtmId = window.__ENV__.tagManagerCatex
      break
  }

  if (window.__ENV__.isWalstore) gtmId = window.__ENV__.tagManagerPuntoDeCompra

  TagManager.initialize({ gtmId })
  TagManager.initialize({ gtmId: window.__ENV__.tagManagerGlobal })
}

export const isToday = (date) => {
  const today = new Date()

  return (
    today.getDate() === date.getDate() &&
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  )
}

export const getWeekDay = (date) => constants.WEEK_DAYS[date.getDay()]

export const hourFormatter = (givenString) => {
  if (givenString.length === 4) {
    return `${givenString.substring(0, 2)}:${givenString.substring(2)}`
  }

  return givenString
}

export const getChannel = (tenant, isWalstore) => {
  if (tenant === constants.tenant.SOD) return constants.channel.SOD
  return constants.channel[isWalstore ? 'WALSTORE' : 'BUYSMART']
}

export const getProductLimit = (productLimit, globalLimit) => (isSod() ? productLimit : globalLimit)

export const isObject = (data) => !(typeof data !== 'object' || data === null || Array.isArray(data))

export const getFlowId = () => getRawItem('XFlowId') || ''

export const formatNumberWithUnit = (number) => {
  const plus = '+'
  const unitSymbols = ['', 'mil', 'mill', 'bill']

  const level = Math.trunc(Math.log10(Math.abs(number)) / 3)

  if (level === 0) return `${number}`
  if (number < 2000) return `${plus}999`

  const suffix = unitSymbols[level]
  const scale = 10 ** (level * 3)
  const respNumber = Math.trunc(number / scale)

  return `${plus}${respNumber}${suffix}`
}

export const isDeviceMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)

export const capitalizeWord = (string = '') =>
  typeof string === 'string' && string.length > 0 ? string[0].toUpperCase() + string.slice(1).toLowerCase() : undefined

export const getCapitalizedText = (normalizedAddress) =>
  normalizedAddress.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))

export const isShowXxYPromotion = (enabledCatexXxYPack, price) =>
  (isSod() || enabledCatexXxYPack) && isProductPack(price)

export const validateSVGFile = (url) => {
  const fileName = url.substring(url.lastIndexOf('/') + 1)
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1)
  return extension.toLowerCase() === 'svg'
}

export const searchParamValueFromLink = (link, paramName) => {
  const queryString = link?.includes('?') ? link.split('?')[1] : undefined
  if (queryString) {
    const queryParams = new URLSearchParams(queryString)
    return queryParams.get(paramName) || undefined
  }
}

export const extractAnalyticDataFromPromoLink = (link) => {
  const adsName = searchParamValueFromLink(link, 'ads_name')
  const adsPosition = searchParamValueFromLink(link, 'ads_position')
  const adsId = searchParamValueFromLink(link, 'ads_id')

  if (!adsName && !adsPosition && !adsId) {
    return undefined
  }

  const position = adsPosition?.split('_')[1]
  const integerPosition = !isNaN(position) ? parseInt(position) : undefined
  return { adsName, position: integerPosition, adsId }
}

export const userFirstNameTruncate = (innerWidth, userFirstName) => {
  if (innerWidth <= 375) {
    return ''
  }

  if (innerWidth <= 384 && userFirstName.length > 10) {
    return textTruncate(userFirstName, 10)
  }

  if (innerWidth <= 398 && userFirstName.length > 13) {
    return textTruncate(userFirstName, 13)
  }

  if (innerWidth <= 768 && userFirstName.length > 15) {
    return textTruncate(userFirstName, 15)
  }

  return userFirstName
}

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object

export const cleanSpecialCharsfromQuery = (query) => query.replace(/%+/g, '').trim()

export const disableLiderAppPopUpForHomeReplenishment = (url) => {
  if (window.__ENV__?.enableHomeReplenishment) {
    const { PARAM, ORIGIN_NAME } = constants.HOME_REPLENISHMENT
    const origin = searchParamValueFromLink(url, PARAM.DISABLED_LIDERAPP_SCREEM)
    const isDisable = origin === ORIGIN_NAME
    setRawItem('shouldOpenPromoteLiderAppPopUp', isDisable)
    return isDisable
  }
  return false
}

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!urlPattern.test(urlString)
}
