import React from 'react'
import PropTypes from 'prop-types'
import StepperButton from './components/StepperButton/'
import './index.css'

const disablePlus = (product) => {
  const { quantity, max } = product
  if (quantity < max) return false
  return true
}

const showQuantity = (product, maxMessage = 'Máx.') => {
  const { quantity } = product

  if (disablePlus(product)) {
    return `${maxMessage} ${quantity}`
  }

  const soldByWeight = product?.attributes?.soldByWeight

  if (soldByWeight === 'true') {
    const defaultQuantity = product?.attributes?.defaultQuantity
    const kg = quantity * defaultQuantity
    return quantity < 100 ? kg.toFixed(1) + 'kg' : '+50kg'
  }

  return quantity
}

const QuantityStepper = ({ onIncrease, onDecrease, product }) => (
  <section>
    <div className="quantity-stepper__container">
      <StepperButton name="Minus" onClick={onDecrease} product={product} />
      <span className="quantity-stepper__quantity">{showQuantity(product)}</span>
      <StepperButton name="Plus" onClick={onIncrease} product={product} disabled={disablePlus(product)} />
    </div>
  </section>
)

QuantityStepper.propTypes = {
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
}

export default QuantityStepper
